.lds-spinner {
  color: inherit;
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 29px;
  width: 5px;
  height: 14px;
  border-radius: 20%;
  background: black;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.lds-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
}
.lds-spinner div:after {
  background: white;
}
/* ----------------------------- Input ----------------------------- */
.loginContainer {
  max-width: 420px;
  width: calc(100% - 10px);
  text-align: center;
  padding: 40px;
  font-family: 'Open Sans', "Helvetica Neue", Arial, Helvetica, Verdana, sans-serif;
  font-weight: 200;
}
.loginContainer a {
  color: #0095FF;
  cursor: pointer;
}
.loginContainer form {
  margin: 10px 0 0 0;
}
.loginContainer form input {
  font-family: 'Open Sans', "Helvetica Neue", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid #CED7DF;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #2E3641;
  background-color: #fff;
  box-sizing: border-box;
  height: 50px;
  padding: 0px 15px;
  display: inline-block;
  margin: 2px 0;
  width: 100%;
  vertical-align: top;
}
.loginContainer .primaryButton {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background: #29AA00;
  color: white;
  margin: 4px 0;
  padding: 0 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color 300ms;
}
.loginContainer .primaryButton:hover {
  background: #54bb33;
}
.loginContainer .primaryButton.disabled {
  background: #aaaaaa !important;
  cursor: not-allowed !important;
}
.loginContainer .primaryButton.facebook {
  background: #3B5998;
}
.loginContainer .primaryButton.facebook:hover {
  background: #627aad;
}
#forgotPassword h4 {
  font-family: 'Raleway', 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  font-size: 30px;
  line-height: 1.5em;
  margin: 0 0 14px;
}
#forgotPassword p {
  font-size: 16px;
  margin: 37px 0;
}
/*
-----------------------------
     Login / Register Popup
-----------------------------
*/
#openPopupBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  opacity: 0.5;
  z-index: 10;
}
#loginRegisterPopup {
  position: fixed;
  top: 46%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background: white;
  border-radius: 5px;
  z-index: 40;
}
#switchToRegisterLink {
  margin: 10px 0 0 0;
}
.switchToLoginLink {
  margin: 36px 0 0 0;
}
#forgotPasswordLink {
  font-size: 12px;
  text-align: right;
}
/* for the ----- or ----- between facebook and email sections */
.or-wrapper {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 1em 0;
}
.or-text {
  position: relative;
  display: inline-block;
}
.or-text:before,
.or-text:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #eee;
}
.or-text:before {
  right: 100%;
  margin-right: 15px;
}
.or-text:after {
  left: 100%;
  margin-left: 15px;
}
/* for the privacy policy agree checkbox */
label.checkToAgree {
  text-transform: none;
  font-size: 1em;
  font-weight: 200;
  margin: 0 0 6px;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  display: flex;
}
.legalNotice {
  text-transform: none;
  font-size: 1em;
  font-weight: 200;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}
label.checkToAgree,
label.checkToAgree input {
  cursor: pointer;
}
label.checkToAgree input[type=checkbox] {
  flex-shrink: 0;
  width: 20px;
}
label.checkToAgree span {
  flex: 1;
  padding-left: 10px;
}
label.checkToAgree span a {
  text-decoration: none;
}
.hr {
  height: 1px;
  background: #eee;
  width: 100%;
  margin: 1em 0;
}
