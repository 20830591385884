@import "base";
@import "spinner";

@fb-blue: #3B5998;

.lds-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);

  div:after {
    background: white;
  }
}

/* ----------------------------- Input ----------------------------- */

.loginContainer {
  max-width: 420px;
  width: calc(100% - 10px);
  text-align: center;
  padding: 40px;
  font-family: 'Open Sans', "Helvetica Neue", Arial, Helvetica, Verdana, sans-serif;
  font-weight: 200;

  a {
    color: @blue;
    cursor: pointer;
  }

  form {
    margin: 10px 0 0 0;

    input {
      font-family: 'Open Sans', "Helvetica Neue", Arial, Helvetica, Verdana, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      border: 1px solid #CED7DF;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      color: #2E3641;
      background-color: #fff;
      box-sizing: border-box;
      height: 50px;
      padding: 0px 15px;
      display: inline-block;
      margin: 2px 0;
      width: 100%;
      vertical-align: top;
    }
  }

  .primaryButton {
    .font-light();
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 50px;
    border-radius: 5px;

    background: @green;
    color: white;
    margin: 4px 0;
    padding: 0 20px;

    cursor: pointer;
    text-align: center;
    text-decoration: none;
    transition: background-color 300ms;

    &:hover {
      background: tint(@green, 20%);
    }

    &.disabled {
      background: @color-secondary !important;
      cursor: not-allowed !important;
    }

    &.facebook {
      background: @fb-blue;

      &:hover {
        background: tint(@fb-blue, 20%);
      }
    }
  }
}

#forgotPassword {
  h4 {
    .font-title-light();
    font-size: 30px;
    line-height: 1.5em;
    margin: 0 0 14px;
  }

  p {
    font-size: 16px;
    margin: 37px 0;
  }
}

/*
-----------------------------
     Login / Register Popup
-----------------------------
*/

#openPopupBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  opacity: 0.5;
  z-index: 10;
}

#loginRegisterPopup {
  position: fixed;
  top: 46%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  background: white;
  border-radius: 5px;
  z-index: 40;
}

#switchToRegisterLink {
  margin: 10px 0 0 0;
}

.switchToLoginLink {
  margin: 36px 0 0 0;
}

#forgotPasswordLink {
  font-size: 12px;
  text-align: right;
}

/* for the ----- or ----- between facebook and email sections */

.or-wrapper {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 1em 0;
}

.or-text {
  position: relative;
  display: inline-block;
}

.or-text:before,
.or-text:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #eee;
}

.or-text:before {
  right: 100%;
  margin-right: 15px;
}

.or-text:after {
  left: 100%;
  margin-left: 15px;
}

/* for the privacy policy agree checkbox */

label.checkToAgree {
  text-transform: none;
  font-size: 1em;
  font-weight: 200;
  margin: 0 0 6px;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  display: flex;
}

.legalNotice {
  text-transform: none;
  font-size: 1em;
  font-weight: 200;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

label.checkToAgree,
label.checkToAgree input {
  cursor: pointer;
}

label.checkToAgree input[type=checkbox] {
  flex-shrink: 0;
  width: 20px;
}

label.checkToAgree span {
  flex: 1;
  padding-left: 10px;
}

label.checkToAgree span a {
  text-decoration: none;
}

.hr {
  height: 1px;
  background: #eee;
  width: 100%;
  margin: 1em 0;
}
