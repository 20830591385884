
// color variables

@color-primary: #333333;
@color-secondary: #aaaaaa;
@background-grey: #fafafa;
@border-color: #eeeeee;
@green: #29AA00;
@red: #FF3000;
@blue: #0095FF;
@salmon: #D46251;
@soft-green: #F3FCF0;

// font-family variables

@font-default: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
@font-title: 'Raleway', @font-default;

// font-mixins

.font-default() {
  font-family: @font-default;
  font-weight: normal;
}

.font-light() {
  font-family: @font-default;
  font-weight: 300;
}

.font-title() {
  font-family: @font-title;
  font-weight: normal;
}

.font-title-light() {
  font-family: @font-title;
  font-weight: 300;
}

.font-bold() {
  font-family: @font-default;
  font-weight: bold;
}
